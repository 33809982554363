import { DynamicImage, Text } from 'components';
import React, { FC } from 'react';
import styled from 'styled-components';
import Paw from 'assets/icons/upgrade/paw.svg';
import Bone from 'assets/icons/upgrade/bone.svg';
import Circle from 'assets/icons/circle-pulse.svg';
import { mobile, tablet } from 'styles/breakpoints';

interface UpsellSectionOneProps {
  image: string;
  title: string;
  subtitle: string;
  list: { title: string; label: string }[];
  bgColor?: string;
  position?: string;
}

const Container = styled.div<{ bgColor?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : '#F3F3F8')};
  width: 100%;
  padding: 4rem 1rem;
  @media ${tablet} {
    padding: 0 1rem 2.25rem;
  }
`;

const InnerContainer = styled.div<{ position?: string }>`
  width: 100%;
  display: flex;
  flex-direction: ${({ position }) => (position ? position : 'row')};
  justify-content: center;
  @media ${tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

const Title = styled(Text)`
  font-feature-settings: "clig" off, "liga" off;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
  max-width: 26.375rem;
  margin-bottom: 0.5rem;
  @media ${tablet} {
    max-width: unset;
    margin-bottom: 0.25rem;
  }
`;

const Subtitle = styled(Text)`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 25.375rem;
`;

const LeftContainer = styled.div`
  max-width: 33.88556rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 2rem;
  @media ${tablet} {
    margin-right: 0;
    margin-bottom: 1rem;
  }
`;

const RightContainer = styled.div`
  max-width: 32.25rem;
  width: 100%;
`;

const ImageStyled = styled(DynamicImage)``;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 25.375rem;
  margin-top: 0.75rem;
  @media ${tablet} {
    margin-top: 1.5rem;
    max-width: unset;
  }
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
`;
const ListTextContainer = styled.div`
  margin-left: 0.75rem;
  @media ${tablet} {
    max-width: unset;
  }
`;

const ListTitle = styled(Text)`
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 0.25rem;
  @media ${tablet} {
    font-size: 1rem;
    line-height: normal;
  }
`;

const ListLabel = styled(Text)`
  color: #000;
  font-size: 0.875rem;
`;

const CircleStyled = styled.div`
  width: 2rem;
  height: 2rem;
`;

const UpsellSectionOne: FC<UpsellSectionOneProps> = ({
  image,
  list,
  title,
  subtitle,
  bgColor,
  position,
}) => {
  const renderItem = () =>
    list.map(({ label, title }, index) => (
      <ListItem key={index}>
        <CircleStyled>
          <Circle />
        </CircleStyled>
        <ListTextContainer>
          <ListTitle>{title}</ListTitle>
          <ListLabel>{label}</ListLabel>
        </ListTextContainer>
      </ListItem>
    ));
  return (
    <Container bgColor={bgColor}>
      <InnerContainer position={position}>
        <LeftContainer>
          <ImageStyled src={image} alt="" />
        </LeftContainer>
        <RightContainer>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
          {subtitle && (
            <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
          )}
          <ListContainer>{renderItem()}</ListContainer>
        </RightContainer>
      </InnerContainer>
    </Container>
  );
};

export default UpsellSectionOne;
